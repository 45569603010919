declare const API_URL: string;
export default {
    // ApiUrl: "http://64.227.184.186:8080/api",
    // ApiAdminUrl: "http://64.227.184.186:8080/api/admin",

    // ApiUrl: "http://api.erp.valuescale.co.in:8090/api",
    // ApiAdminUrl: "http://api.erp.valuescale.co.in:8090/api/admin",

    XeroUrl: "https://api-inv.valuescale.co.in",
    ApiUrl: "https://api-inv.valuescale.co.in/api",
    ApiAdminUrl: "https://api-inv.valuescale.co.in/api/admin",



    // XeroUrl: "http://localhost:8002",
    // ApiUrl: "http://localhost:8002/api",
    // ApiAdminUrl: "http://localhost:8002/api/admin",
};
